import React from "react";
// import { Waypoint } from "react-waypoint";
import Hero from "./Hero";
import Registration from "./Registration";
import Blogs from "./Blogs";
// import Testimonials from "./Testimonials";
// const SkillsAnimationItem = ({ children }) => {
//   const handleWaypointEnter = () => {
//     const progressBars = document.querySelectorAll(".progress .progress-bar");
//     progressBars.forEach((bar) => {
//       const val = bar.getAttribute("aria-valuenow");
//       bar.style.width = `${val}%`;
//     });
//   };

//   return (
//     <Waypoint onEnter={handleWaypointEnter}>
//       <div className="row skills-content skills-animation">{children}</div>
//     </Waypoint>
//   );
// };
const Body = () => {
  return (
    <main className="main">
      <Hero />
      <Blogs />
      {/* <Clients /> */}
      <Registration />
    </main>
  );
};

export default Body;
