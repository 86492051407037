import React from "react";
import logo from "../../assets/img/mtLogoSmall.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer id="footer" className="footer position-relative">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-6 col-md-12 footer-about">
            <a href="/" className="logo d-flex align-items-center">
              <span className="sitename">
                <img src={logo} alt="" />
                MOBITRA | Vedum
              </span>
            </a>
            <p>Full Customer Experience Service</p>
            <div className="social-links d-flex mt-4">
              <Link href="/">
                <i className="bi bi-twitter-x"></i>
              </Link>
              <Link href="/">
                <i className="bi bi-facebook"></i>
              </Link>
              <Link href="/">
                <i className="bi bi-instagram"></i>
              </Link>
              <a
                href="https://in.linkedin.com/company/mobitra"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
            <div className="social-links d-flex mt-4">
              <p>
                © <span>{new Date().getFullYear()}</span>{" "}
                <strong className="sitename">Mobitra.</strong>{" "}
                <span>All Rights Reserved</span>
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-6 footer-links">
            <h4>COMPANY</h4>
            <ul>
              <li>
                <a href="/#hero">Home</a>
              </li>
              <li>
                <a href="/#registration">Registration</a>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy policy</Link>
              </li>
            </ul>
          </div>

          {/* <div className="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li>
                <a href="#">Web Design</a>
              </li>
              <li>
                <a href="#">Web Development</a>
              </li>
              <li>
                <a href="#">Product Management</a>
              </li>
              <li>
                <a href="#">Marketing</a>
              </li>
              <li>
                <a href="#">Graphic Design</a>
              </li>
            </ul>
          </div> */}

          {/* <div className="col-lg-4 col-md-12 footer-contact footer-links text-md-start">
            <h4>CONTACT US</h4>
            <p className="contact-head">Locations:</p>
            <p>
              E-14, Surajpur Industrial Area, Site5, Noida, Gautam Budha
              Nagar-201308, Uttar Pradesh, India
            </p>
            <p>
              United Kingdom: Village Way, Pinner, United Kingdom, Postcode:
              HA55AF
            </p>
            <p className="mt-4">
              <strong>Email:</strong> <span>info@mobitra.in</span>
            </p>
            <p>
              <strong>Customer Care: </strong>
            </p>
            <p>FleetGo: +91 80811 75758 / +91 91258 59377</p>
          </div>
          <div className="col-lg-3 col-12 footer-contact text-md-start">
            <h4>
              STAY UP TO DATE <i className="fa fa-arrow" aria-hidden="true"></i>
            </h4>
            <p className="contact-head">Subscribe to our newsletter.</p>
            <div className="form-group mb-3 emailInputField">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
              />
              <span>
                <span className="material-symbols-outlined">arrow_forward</span>
              </span>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span> <strong className="sitename"></strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        <div className="credits">
          Designed by <a href=""></a>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
