import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } from "../Common/main.js";

emailjs.init(PUBLIC_KEY);

const Registration = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sentMessage, setSentMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleReset = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSentMessage("");
    const { name, email, subject, message, phone } = formData;
    if (!name || !email || !subject || !message || !phone) {
      console.log("All fields are mandatory");
      return;
    }
    try {
      const result = await emailjs.send(SERVICE_ID, TEMPLATE_ID, formData);

      if (result.text === "OK") {
        setSentMessage("Your message has been sent. Thank you!");
        setError("");
        handleReset();
      } else {
        setError("Failed to send the message. Please try again later.");
        handleReset();
      }
    } catch (error) {
      setError("Failed to send the message. Please try again later.");
      handleReset();
    } finally {
      setLoading(false);
      setTimeout(() => {
        setError("");
        setSentMessage("");
      }, 5000);
    }
  };
  return (
    <section id="registration" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>REGISTRATION</h2>
        <p>
          Stay connected! Register your interest now and be the first to know
          about our latest updates.
        </p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-12">
            <form
              className="php-email-form"
              data-aos="fade-up"
              data-aos-delay="200"
              onSubmit={handleSubmit}
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="phone"
                    className="form-control"
                    name="phone"
                    placeholder="Your Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="6"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                <div className="col-md-12 text-center">
                  {loading && <div className="d-block loading">Loading...</div>}
                  {error && (
                    <div className="d-block error-message">{error}</div>
                  )}
                  {sentMessage && (
                    <div className="d-block sent-message">{sentMessage}</div>
                  )}

                  <button
                    type="submit"
                    disabled={loading}
                    // disabled
                    // onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;
