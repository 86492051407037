import React from "react";
import heroSecBg from "../../assets/img/law-ai-banner.jpg";
// import heroSecVid from "../../assets/hero-warp.mp4";
const Hero = () => {
  return (
    <section id="hero" className="hero section">
      <img src={heroSecBg} alt="" data-aos="fade-in" />

      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            {/* <h2 data-aos="fade-up" data-aos-delay="100">
                Welcome to Our Website
              </h2> */}
            <h2
              // style={{ textTransform: "uppercase" }}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Vedum
            </h2>
            <h2
              // style={{ textTransform: "uppercase" }}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              a Mobitra's AI platform
            </h2>
            <p data-aos="fade-up" data-aos-delay="200">
              An AI driven solution to transform the way tax lawyers &
              consultants currently work
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
